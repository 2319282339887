<template>
  <div class="contact">
    <b-card class="text-center">
      <h1>{{ $t('contact.title') }}</h1>
      <p>{{ $t('contact.text') }}</p>
      <div v-if="success == true"><b-alert variant="success" show>{{ $t('contact.success') }}</b-alert></div>
      <div v-else-if="success == false"><b-alert variant="danger" show>{{ $t('contact.error') }}</b-alert></div>
      <b-form @submit="onSubmit" v-if="show">
        <b-form-group id="input-group-1" :label="$t('contact.form.email')" label-for="input-1">
          <b-form-input id="input-1" v-model="form.email" type="email" required></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" :label="$t('contact.form.name')" label-for="input-2">
          <b-form-input id="input-2" v-model="form.name" required></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" :label="$t('contact.form.message')" label-for="input-3">
          <b-form-textarea id="input-3" v-model="form.text" rows="8" max-rows="25"></b-form-textarea>
        </b-form-group>
        <b-button type="submit">{{ $t('forms.submit') }}</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
  export default {
    name: 'Contact',
    data() {
      return {
        form: {
          email: '',
          name: '',
          text: '',
        },
        success: null,
        show: true,
        result: null
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        fetch("https://platformmail.toekomstperspectief.be/api/v1/website.php?email=" + this.form.email + "&name=" + this.form.name + "&message=" + this.form.text, {
          "method": "GET",
        })
        .then(response => {
          if(response.ok){
            return response.json()
          //} else {
            //console.log('Error returned');
          }
        })
        .then(response => {
          this.result = response.body;
          this.success = true;
          this.form.email = '';
          this.form.name = '';
          this.form.text = '';
        })
        //.catch(err => {
          //console.log(err);
        //});
      }
    }
  }
</script>
